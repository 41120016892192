import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslations } from '../translations';
import { useLanguage } from '../context/LanguageContext';

const Card = styled.article`
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
`;

const CardImage = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    position: relative;
    overflow: hidden;
    
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
    }
    
    ${Card}:hover & img {
        transform: scale(1.05);
    }
`;

const CardCategory = styled.span`
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: var(--primary-blue);
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 600;
    z-index: 1;
`;

const CardContent = styled.div`
    padding: 1.25rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const CardTitle = styled.h3`
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    color: var(--text-primary);
    
    a {
        color: inherit;
        text-decoration: none;
        
        &:hover {
            color: var(--primary-blue);
        }
    }
`;

const CardMeta = styled.div`
    font-size: 0.85rem;
    color: #666;
    margin-bottom: 1rem;
`;

const CardExcerpt = styled.div`
    color: var(--text-primary);
    margin-bottom: 1.25rem;
    line-height: 1.5;
    flex-grow: 1;
`;

const ReadMoreLink = styled(Link)`
    display: inline-block;
    color: var(--primary-blue);
    font-weight: 600;
    text-decoration: none;
    align-self: flex-start;
    
    &:hover {
        text-decoration: underline;
    }
    
    &:after {
        content: ' →';
    }
`;

function GameCard({ post, categorySlug }) {
    const t = useTranslations();
    const { language } = useLanguage();
    
    // Format date according to language
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat(
            language === 'es' ? 'es-ES' : 'en-US', 
            {
                year: 'numeric',
                month: 'long', 
                day: 'numeric'
            }
        ).format(date);
    };
    
    // Get category name from the post
    const getCategoryName = () => {
        if (post._embedded && post._embedded['wp:term'] && post._embedded['wp:term'][0]) {
            const categories = post._embedded['wp:term'][0];
            return categories[0] ? categories[0].name : '';
        }
        return '';
    };
    
    // Create permalink
    const permalink = `/${categorySlug || post.categories_slugs?.[0] || 'post'}/${post.slug}`;
    
    // Get featured image
    const getFeaturedImage = () => {
        console.log(post);
        console.log(post._embedded);
        console.log(post._embedded['wp:featuredmedia']);
        if (post._embedded && post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0]) {
            return post._embedded['wp:featuredmedia'][0].source_url;
        }
        return '/placeholder-game.jpg'; // Default placeholder
    };
    
    return (
        <Card>
            <CardImage>
                <CardCategory>{getCategoryName()}</CardCategory>
                <img src={getFeaturedImage()} alt={post.title.rendered} />
            </CardImage>
            
            <CardContent>
                <CardTitle>
                    <Link to={permalink} dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                </CardTitle>
                
                <CardMeta>{formatDate(post.date)}</CardMeta>
                
                <CardExcerpt dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                
                <ReadMoreLink to={permalink}>
                    {t.readMore}
                </ReadMoreLink>
            </CardContent>
        </Card>
    );
}

export default GameCard;
