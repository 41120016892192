import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslations } from '../translations';
import axios from 'axios';

const ContactWrapper = styled.div`
    max-width: 800px;
    margin: 2rem auto;
    padding: 1rem;
    color: #333;
    line-height: 1.6;
`;

const Title = styled.h1`
    font-size: 2rem;
    color: #2768CB;
    margin-bottom: 1.5rem;
`;

const Subtitle = styled.h2`
    font-size: 1.5rem;
    color: #2768CB;
    margin-top: 2rem;
    margin-bottom: 1rem;
`;

const ContactInfo = styled.div`
    margin-bottom: 2rem;
    text-align: center;
`;

const EmailDisplay = styled.div`
    font-size: 1.2rem;
    margin: 1rem 0;
    font-family: monospace;
    letter-spacing: 0.5px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const Label = styled.label`
    font-weight: bold;
`;

const Input = styled.input`
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    font-size: 1rem;
`;

const TextArea = styled.textarea`
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    font-size: 1rem;
    min-height: 150px;
    resize: vertical;
`;

const Button = styled.button`
    padding: 0.8rem 1.5rem;
    background-color: #2768CB;
    color: white;
    border: none;
    border-radius: 0.25rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    
    &:hover {
        background-color: #1b4f8a;
    }
    
    &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }
`;

const ErrorMessage = styled.div`
    color: #d32f2f;
    font-size: 0.875rem;
    margin-top: 0.25rem;
`;

const SuccessMessage = styled.div`
    color: #2e7d32;
    font-size: 1rem;
    padding: 1rem;
    background-color: #e8f5e9;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
`;

function Contact() {
    const t = useTranslations();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState('');
    
    const validateForm = () => {
        const newErrors = {};
        
        if (!formData.name.trim()) {
            newErrors.name = t.contactPage.nameRequired;
        }
        
        if (!formData.email.trim()) {
            newErrors.email = t.contactPage.emailRequired;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = t.contactPage.emailInvalid;
        }
        
        if (!formData.message.trim()) {
            newErrors.message = t.contactPage.messageRequired;
        }
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        
        // Clear the error for this field when user starts typing
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: ''
            });
        }
        
        // Clear the submission status messages when form is modified after submission
        if (submitSuccess || submitError) {
            setSubmitSuccess(false);
            setSubmitError('');
        }
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return;
        }
        
        setIsSubmitting(true);
        setSubmitError('');
        
        try {
            // Get WordPress REST API root URL from window object (usually added by WordPress)
            // If not available, try to construct it
            const apiRoot = window.wpApiSettings?.root || '/wp-json';
            
            // Send message to WordPress
            await axios.post(`${apiRoot}/contact-form/v1/submit`, {
                name: formData.name,
                email: formData.email,
                subject: formData.subject || t.contactPage.defaultSubject,
                message: formData.message
            });
            
            // On success
            setSubmitSuccess(true);
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
            });
            
            // Reset success message after a while
            setTimeout(() => {
                setSubmitSuccess(false);
            }, 5000);
        } catch (error) {
            console.error('Error submitting contact form:', error);
            setSubmitError(
                error.response?.data?.message || t.contactPage.submitError
            );
        } finally {
            setIsSubmitting(false);
        }
    };
    
    return (
        <ContactWrapper>
            <Title>{t.contactPage.title}</Title>
            
            <ContactInfo>
                <p>{t.contactPage.intro}</p>
                <EmailDisplay>
                    juan at ilmaregames.com.ar
                </EmailDisplay>
            </ContactInfo>
            
            <Subtitle>{t.contactPage.formTitle}</Subtitle>
            
            {submitSuccess && (
                <SuccessMessage>
                    {t.contactPage.submitSuccess}
                </SuccessMessage>
            )}
            
            {submitError && (
                <ErrorMessage style={{ padding: '1rem', marginBottom: '1rem' }}>
                    {submitError}
                </ErrorMessage>
            )}
            
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label htmlFor="name">{t.contactPage.name}</Label>
                    <Input 
                        type="text" 
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                </FormGroup>
                
                <FormGroup>
                    <Label htmlFor="email">{t.contactPage.email} *</Label>
                    <Input 
                        type="email" 
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                </FormGroup>
                
                <FormGroup>
                    <Label htmlFor="subject">{t.contactPage.subject}</Label>
                    <Input 
                        type="text" 
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                    />
                </FormGroup>
                
                <FormGroup>
                    <Label htmlFor="message">{t.contactPage.message}</Label>
                    <TextArea 
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                    />
                    {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
                </FormGroup>
                
                <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? t.contactPage.submitting : t.contactPage.submit}
                </Button>
            </Form>
        </ContactWrapper>
    );
}

export default Contact;
