import React from 'react';
import styled from 'styled-components';
import { useTranslations } from '../translations';

const Wrapper = styled.div`
    max-width: 800px;
    margin: 2rem auto;
    padding: 1rem;
    color: #333;
    line-height: 1.6;
`;

const SectionTitle = styled.h2`
    font-size: 1.5rem;
    color: #2768CB;
    margin-top: 1.5rem;
`;

function PrivacyPolicy() {
    const t = useTranslations();
    
    return (
        <Wrapper>
            <h1>{t.privacy.title}</h1>
            <p>
                {t.privacy.intro1}
            </p>
            <p>
                {t.privacy.intro2}
            </p>

            <SectionTitle>{t.privacy.logFiles.title}</SectionTitle>
            <p>
                {t.privacy.logFiles.content}
            </p>

            <SectionTitle>{t.privacy.cookies.title}</SectionTitle>
            <p>
                {t.privacy.cookies.content}
            </p>

            <SectionTitle>{t.privacy.dart.title}</SectionTitle>
            <p>
                {t.privacy.dart.content} <a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">Google's Ads Settings</a>.
            </p>

            <SectionTitle>{t.privacy.analytics.title}</SectionTitle>
            <p>
                {t.privacy.analytics.content}
            </p>

            <SectionTitle>{t.privacy.management.title}</SectionTitle>
            <p>
                {t.privacy.management.content1}
            </p>
            <p>
                {t.privacy.management.content2}
            </p>

            <p>
                {t.privacy.contact} info at ilmaregames.com.ar.
            </p>
        </Wrapper>
    );
}

export default PrivacyPolicy;
