import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import GameList from './components/GameList';
import GameDetail from './components/GameDetail';
import PostDetail from './components/PostDetail';
import MainMenu from './components/MainMenu';
import Footer from './components/Footer';
import SearchResults from './components/SearchResults';
import CookieConsent from './components/CookieConsent';
import PrivacyPolicy from './components/PrivacyPolicy';
import About from './components/About';
import Contact from './components/Contact';
import ErrorBoundary from './components/ErrorBoundary';
import { LanguageProvider } from './context/LanguageContext';

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const MainContent = styled.main`
    flex: 1;
`;

function App() {
    const [categories, setCategories] = useState([]);

    // Cargar categorías de WordPress al montar el componente
    useEffect(() => {
        fetch('/wp-json/wp/v2/categories?per_page=100')
            .then(response => response.json())
            .then(data => setCategories(data))
            .catch(error => console.error('Error fetching categories:', error));
    }, []);

    return (
        <LanguageProvider>
            <BrowserRouter>
                <ErrorBoundary>
                    <AppContainer>
                        <MainMenu allCategories={categories}/>
                        <MainContent>
                            <Routes>
                                <Route path="/" element={<GameList allCategories={categories}/>} />
                                <Route path="/category/:categorySlug" element={<GameList allCategories={categories}/>} />
                                <Route path="/search" element={<SearchResults />} />
                                <Route path="/privacy" element={<PrivacyPolicy />} />
                                <Route path="/about" element={<About />} />
                                <Route path="/contact" element={<Contact />} />
                                {/* Ruta para artículos tradicionales */}
                                <Route path="/:categorySlug/:postSlug" element={<PostDetail />} />
                            </Routes>
                        </MainContent>
                        <Footer />            
                        <CookieConsent />
                    </AppContainer>
                </ErrorBoundary>
            </BrowserRouter>
        </LanguageProvider>
    );
}

export default App;
