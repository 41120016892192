import { useLanguage } from '../context/LanguageContext';

// English translations
export const en = {
  // Common
  home: "Home",
  about: "About",
  contact: "Contact",
  loading: "Loading...",
  error: "Error",
  notFound: "Not found",
  backToHome: "Back to home",
  readMore: "Read more",
  allRightsReserved: "All rights reserved",
  
  // Main Menu
  mainMenu: {
    games: "Games",
    prototypes: "Prototypes",
    news: "News",
    ia: "AI"
  },
  
  // About Page
  credits: {
    name: "Juan Ignacio Albanesi",
    title: "Independent Game Developer & Founder of ilMare Games",
    bioTitle: "About Me",
    bio1: "I've been an independent game developer since 2010, founding ilMare Games with a passion for creating engaging and innovative gaming experiences. My journey in game development spans over a decade, during which I've developed a diverse portfolio of games for both mobile and web platforms.",
    bio2: "I constantly strive to innovate and explore new ideas in game development. Whether it's experimenting with new mechanics, technologies, or gameplay concepts, I enjoy pushing boundaries and creating unique experiences for players.",
    bio3: "After taking a brief hiatus when my two daughters were born, I've returned to game development with renewed energy and passion. My creative flame has been reignited, and I'm excited to continue building new games and experiences.",
    gamesTitle: "Notable Games",
    games: [
      {
        title: "Gravity Football",
        description: "A physics-based sports game that challenges the traditional rules of football with gravity manipulation mechanics."
      },
      {
        title: "Xee Bee",
        description: "An aerial acrobatics game with innovative and fun mechanics."
      },
      {
        title: "Realms of Idle",
        description: "A strategic idle game that combines resource management with fantasy world-building."
      }
    ],
    contactTitle: "Get in Touch",
    contactText: "If you're interested in collaborating on a project, have questions about my games, or just want to connect, feel free to reach out via LinkedIn or email."
  },
  
  // Cookie Consent
  cookies: {
    message: "This site uses cookies to improve your experience. By using it, you accept our",
    privacyPolicy: "Privacy Policy",
    accept: "Accept"
  },
  
  // Post Detail
  post: {
    in: "in",
    comments: "Comments",
    commentsClosed: "Comments are closed.",
    articleNotFound: "The article was not found.",
    loadError: "The requested article could not be loaded.",
    share: "Share on",
    leaveComment: "Leave a comment",
    name: "Name",
    email: "Email",
    comment: "Comment",
    submitComment: "Submit Comment",
    submitting: "Submitting...",
    commentSuccess: "Comment submitted successfully! It will be visible after moderation.",
    commentError: "There was an error submitting your comment.",
    commentContentRequired: "Comment content is required.",
    commentRateLimited: "You're commenting too quickly. Please wait a moment before trying again.",
    noComments: "No comments yet. Be the first to comment!",
    loginRequired: "Sorry, you must be logged in to comment.",
    login: "Log in",
    register: "register",
    orRegister: "or",
    rememberMe: "Save my name and email in this browser for the next time I comment",
    optional: "optional",
    anonymous: "Anonymous",
    emailPrivate: "Never published",
    commentPlaceholder: "Your comment here..."
  },
  
  // Game List
  gameList: {
    allArticles: "All Articles",
    loadingArticles: "Loading articles...",
    loadMore: "Load More"
  },
  
  // Game Detail
  gameDetail: {
    play: "Play",
    relatedGames: "Related Games",
    description: "Description",
    instructions: "Instructions",
    showMore: "Show more",
    showLess: "Show less",
    loading: "Loading...",
    loadingGameDetails: "Loading game details..."
  },
  
  // Privacy Policy
  privacy: {
    title: "Privacy Policy for ilmaregames.com.ar",
    intro1: "The privacy of our visitors at ilmaregames.com.ar is very important to us.",
    intro2: "At ilmaregames.com.ar, we recognize the importance of protecting your personal information. This document outlines the types of personal information we collect and record when you visit our website, and how we use and protect that information. We never sell your personal information to third parties.",
    logFiles: {
      title: "Log Files",
      content: "Like most websites, we collect and use data in log files. This data includes your IP address, your Internet Service Provider (ISP), the browser used to access our site (such as Chrome, Firefox, or Safari), the time of your visit, and the pages visited throughout our site. This information is used to analyze trends, administer the site, and understand user interaction patterns. We do not link this data to personally identifiable information."
    },
    cookies: {
      title: "Cookies and Web Beacons",
      content: "We use cookies to store information about your personal preferences when you visit our site. This may include displaying a popup only once per visit or enabling certain site features. We also use third-party services to show ads on ilmaregames.com.ar. Some advertisers may use cookies or web beacons to collect data such as your IP address, your ISP, the browser used to access our site, and in some cases, whether Flash is installed. This information is generally used for geotargeting purposes (showing location-specific ads) or displaying relevant ads based on visited sites."
    },
    dart: {
      title: "DoubleClick DART Cookies",
      content: "We may use Google DART cookies for ad serving through Google's DoubleClick. This cookie is placed on your computer when you browse sites using DoubleClick advertising, including some Google AdSense ads. It allows ads to be served to you based on your interests by tracking visits to this and other websites. DART uses \"non-personally identifiable information\" and does not track personal details such as your name, email address, or physical address. You can opt out of interest-based advertising by visiting"
    },
    analytics: {
      title: "User Analytics",
      content: "At ilmaregames.com.ar, we use analytics tools to track user behavior and interactions on our site. This includes metrics such as visit duration, pages viewed, and navigation patterns. This data helps us improve user experience and optimize our site. All information gathered is aggregated and does not include personally identifiable information."
    },
    management: {
      title: "Cookie Management",
      content1: "You may choose to disable cookies through your individual browser options or manage preferences in Internet security programs. However, disabling cookies may affect your ability to interact with our site and other websites. This could include the inability to log in to services or access certain features.",
      content2: "Deleting cookies does not permanently opt you out of any advertising programs. Unless you have settings that disallow cookies, a new cookie will be created the next time you visit a site running advertisements."
    },
    contact: "For more information or if you have any questions regarding our privacy policy, please contact us at"
  },
  
  // Contact Page
  contactPage: {
    title: "Contact Us",
    intro: "Have a question or want to collaborate? Feel free to get in touch with us!",
    formTitle: "Send a Message",
    name: "Name",
    email: "Email",
    subject: "Subject",
    message: "Message",
    submit: "Send Message",
    submitting: "Sending...",
    submitSuccess: "Your message has been sent successfully. We'll get back to you soon!",
    submitError: "There was an error sending your message. Please try again later.",
    nameRequired: "Name is required",
    emailRequired: "Email is required",
    emailInvalid: "Please enter a valid email address",
    messageRequired: "Message is required",
    defaultSubject: "Website Contact Form"
  }
};

// Spanish translations
export const es = {
  // Common
  home: "Inicio",
  about: "Acerca de",
  contact: "Contacto",
  loading: "Cargando...",
  error: "Error",
  notFound: "No encontrado",
  backToHome: "Volver al inicio",
  readMore: "Leer más",
  allRightsReserved: "Todos los derechos reservados",
  
  // Main Menu
  mainMenu: {
    games: "Juegos",
    prototypes: "Prototipos",
    news: "Noticias",
    ia: "IA"
  },
  
  // About Page
  credits: {
    name: "Juan Ignacio Albanesi",
    title: "Desarrollador de Juegos Independiente & Fundador de ilMare Games",
    bioTitle: "Sobre Mí",
    bio1: "He sido desarrollador de juegos independiente desde 2010, fundando ilMare Games con la pasión de crear experiencias de juego atractivas e innovadoras. Mi trayectoria en el desarrollo de juegos abarca más de una década, durante la cual he desarrollado un portafolio diverso de juegos tanto para plataformas móviles como web.",
    bio2: "Constantemente me esfuerzo por innovar y explorar nuevas ideas en el desarrollo de juegos. Ya sea experimentando con nuevas mecánicas, tecnologías o conceptos de jugabilidad, disfruto empujando los límites y creando experiencias únicas para los jugadores.",
    bio3: "Después de tomar un breve descanso cuando nacieron mis dos hijas, he vuelto al desarrollo de juegos con energía y pasión renovadas. Mi llama creativa se ha reencendido, y estoy emocionado de seguir construyendo nuevos juegos y experiencias.",
    gamesTitle: "Juegos Destacados",
    games: [
      {
        title: "Gravity Football",
        description: "Un juego deportivo basado en física que desafía las reglas tradicionales del fútbol con mecánicas de manipulación de gravedad."
      },
      {
        title: "Xee Bee",
        description: "Un juego de acrobacias aereas con mecánicas innovadoras y divertidas."
      },
      {
        title: "Realms of Idle",
        description: "Un juego idle estratégico que combina la gestión de recursos con la construcción de mundos de fantasía."
      }
    ],
    contactTitle: "Ponete en Contacto",
    contactText: "Si estás interesado en colaborar en un proyecto, tenes preguntas sobre mis juegos, o simplemente quieres conectar, no dudes en contactarme a través de LinkedIn o correo electrónico."
  },
  
  // Cookie Consent
  cookies: {
    message: "Este sitio utiliza cookies para mejorar tu experiencia. Al usarlo, aceptas nuestra",
    privacyPolicy: "Política de Privacidad",
    accept: "Aceptar"
  },
  
  // Post Detail
  post: {
    in: "en",
    comments: "Comentarios",
    commentsClosed: "Los comentarios están cerrados.",
    articleNotFound: "El artículo no fue encontrado.",
    loadError: "No se pudo cargar el artículo solicitado.",
    share: "Compartir en",
    leaveComment: "Dejar un comentario",
    name: "Nombre",
    email: "Correo electrónico",
    comment: "Comentario",
    submitComment: "Enviar Comentario",
    submitting: "Enviando...",
    commentSuccess: "¡Comentario enviado correctamente! Será visible después de la moderación.",
    commentError: "Hubo un error al enviar tu comentario.",
    commentContentRequired: "El contenido del comentario es obligatorio.",
    commentRateLimited: "Estás comentando demasiado rápido. Por favor, espera un momento antes de intentarlo de nuevo.",
    noComments: "Aún no hay comentarios. ¡Sé el primero en comentar!",
    loginRequired: "Lo sentimos, debes iniciar sesión para comentar.",
    login: "Iniciar sesión",
    register: "registrarse",
    orRegister: "o",
    rememberMe: "Guardar mi nombre y correo electrónico en este navegador para la próxima vez que comente",
    optional: "opcional",
    anonymous: "Anónimo",
    emailPrivate: "Nunca se publica",
    commentPlaceholder: "Tu comentario aquí..."
  },
  
  // Game List
  gameList: {
    allArticles: "Todos los Artículos",
    loadingArticles: "Cargando artículos...",
    loadMore: "Cargar Más"
  },
  
  // Game Detail
  gameDetail: {
    play: "Jugar",
    relatedGames: "Juegos Relacionados",
    description: "Descripción",
    instructions: "Instrucciones",
    showMore: "Mostrar más",
    showLess: "Mostrar menos",
    loading: "Cargando...",
    loadingGameDetails: "Cargando detalles del juego..."
  },
  
  // Privacy Policy
  privacy: {
    title: "Política de Privacidad para ilmaregames.com.ar",
    intro1: "La privacidad de nuestros visitantes en ilmaregames.com.ar es muy importante para nosotros.",
    intro2: "En ilmaregames.com.ar, reconocemos la importancia de proteger su información personal. Este documento describe los tipos de información personal que recopilamos y registramos cuando visita nuestro sitio web, y cómo usamos y protegemos esa información. Nunca vendemos su información personal a terceros.",
    logFiles: {
      title: "Archivos de Registro",
      content: "Como la mayoría de los sitios web, recopilamos y utilizamos datos en archivos de registro. Estos datos incluyen su dirección IP, su Proveedor de Servicios de Internet (ISP), el navegador utilizado para acceder a nuestro sitio (como Chrome, Firefox o Safari), la hora de su visita y las páginas visitadas en nuestro sitio. Esta información se utiliza para analizar tendencias, administrar el sitio y comprender los patrones de interacción del usuario. No vinculamos estos datos con información personalmente identificable."
    },
    cookies: {
      title: "Cookies y Web Beacons",
      content: "Utilizamos cookies para almacenar información sobre sus preferencias personales cuando visita nuestro sitio. Esto puede incluir mostrar un popup solo una vez por visita o habilitar ciertas funciones del sitio. También utilizamos servicios de terceros para mostrar anuncios en ilmaregames.com.ar. Algunos anunciantes pueden usar cookies o web beacons para recopilar datos como su dirección IP, su ISP, el navegador utilizado para acceder a nuestro sitio y, en algunos casos, si Flash está instalado. Esta información generalmente se utiliza para fines de geolocalización (mostrar anuncios específicos según la ubicación) o mostrar anuncios relevantes basados en los sitios visitados."
    },
    dart: {
      title: "Cookies DART de DoubleClick",
      content: "Podemos utilizar cookies DART de Google para la publicación de anuncios a través de DoubleClick de Google. Esta cookie se coloca en su computadora cuando navega por sitios que utilizan publicidad DoubleClick, incluidos algunos anuncios de Google AdSense. Permite que se le muestren anuncios basados en sus intereses mediante el seguimiento de visitas a este y otros sitios web. DART utiliza \"información no personalmente identificable\" y no rastrea datos personales como su nombre, dirección de correo electrónico o dirección física. Puede optar por no recibir publicidad basada en intereses visitando"
    },
    analytics: {
      title: "Análisis de Usuarios",
      content: "En ilmaregames.com.ar, utilizamos herramientas de análisis para rastrear el comportamiento y las interacciones de los usuarios en nuestro sitio. Esto incluye métricas como la duración de la visita, las páginas vistas y los patrones de navegación. Estos datos nos ayudan a mejorar la experiencia del usuario y optimizar nuestro sitio. Toda la información recopilada es agregada y no incluye información de identificación personal."
    },
    management: {
      title: "Gestión de Cookies",
      content1: "Puede optar por deshabilitar las cookies a través de las opciones individuales de su navegador o administrar las preferencias en los programas de seguridad de Internet. Sin embargo, deshabilitar las cookies puede afectar su capacidad para interactuar con nuestro sitio y otros sitios web. Esto podría incluir la imposibilidad de iniciar sesión en servicios o acceder a ciertas funciones.",
      content2: "Eliminar las cookies no lo excluye permanentemente de ningún programa publicitario. A menos que tenga configuraciones que no permitan cookies, se creará una nueva cookie la próxima vez que visite un sitio que ejecute anuncios."
    },
    contact: "Para más información o si tiene alguna pregunta sobre nuestra política de privacidad, contáctenos en"
  },
  
  // Contact Page
  contactPage: {
    title: "Contáctanos",
    intro: "¿Tienes alguna pregunta o quieres colaborar? ¡No dudes en ponerte en contacto con nosotros!",
    formTitle: "Enviar un Mensaje",
    name: "Nombre",
    email: "Correo electrónico",
    subject: "Asunto",
    message: "Mensaje",
    submit: "Enviar Mensaje",
    submitting: "Enviando...",
    submitSuccess: "Tu mensaje ha sido enviado con éxito. ¡Te responderemos pronto!",
    submitError: "Hubo un error al enviar tu mensaje. Por favor, inténtalo de nuevo más tarde.",
    nameRequired: "El nombre es obligatorio",
    emailRequired: "El correo electrónico es obligatorio",
    emailInvalid: "Por favor, introduce una dirección de correo electrónico válida",
    messageRequired: "El mensaje es obligatorio",
    defaultSubject: "Formulario de Contacto del Sitio Web"
  }
};

// Hook to get translations based on current language
export const useTranslations = () => {
  const { language } = useLanguage();
  return language === 'es' ? es : en;
};
