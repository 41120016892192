import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import RuffleWrapper from './RuffleWrapper';

const ContentWrapper = styled.div`
  line-height: 1.6;
  color: var(--text-primary);
  
  h1, h2, h3, h4, h5, h6 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  
  p {
    margin-bottom: 1rem;
  }
  
  img {
    max-width: 100%;
    height: auto;
    margin: 1rem 0;
    border-radius: 4px;
  }
  
  ul, ol {
    margin-bottom: 1rem;
    padding-left: 2rem;
  }
  
  blockquote {
    border-left: 4px solid var(--primary-blue);
    padding-left: 1rem;
    margin-left: 0;
    color: #555;
    font-style: italic;
  }
  
  pre, code {
    background-color: var(--gray-light);
    border-radius: 4px;
    padding: 0.2rem 0.4rem;
    font-family: monospace;
  }
  
  pre {
    padding: 1rem;
    overflow-x: auto;
  }
  
  iframe {
    max-width: 100%;
    border: none;
    margin: 1rem 0;
  }
  
  a {
    color: var(--primary-blue);
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .wp-block-image {
    margin: 1rem 0;
    text-align: center;
    
    figcaption {
      font-size: 0.85rem;
      color: #666;
      margin-top: 0.5rem;
    }
  }
`;

function PostContent({ content }) {
  // Process shortcodes in content
  const processContent = () => {
    if (!content) return '';
    
    // Process the HTML content directly instead of converting to text
    let processedContent = content;
    
    // Process [swf] shortcodes
    processedContent = processSWFShortcodes(processedContent);
    
    // Process [iframe] shortcodes
    processedContent = processIframeShortcodes(processedContent);
    
    return processedContent;
  };
  
  // Handle [swf] shortcodes with RuffleWrapper
  const processSWFShortcodes = (content) => {
    // Find all [swf] shortcodes in content
    const swfRegex = /\[swf\](.*?),(.*?),(.*?)\[\/swf\]/g;
    let match;
    let processedContent = content;
    
    while ((match = swfRegex.exec(content)) !== null) {
      const fullMatch = match[0];
      const src = match[1];
      const width = parseInt(match[2]) || 800;
      const height = parseInt(match[3]) || 600;
      
      // Create a unique ID for this embed
      const embedId = `swf-embed-${Math.random().toString(36).substr(2, 9)}`;
      
      // Replace shortcode with a placeholder div that we'll replace with React component
      processedContent = processedContent.replace(
        fullMatch,
        `<div id="${embedId}" data-src="${src}" data-width="${width}" data-height="${height}" class="swf-embed"></div>`
      );
    }
    
    return processedContent;
  };
  
  // Handle [iframe] shortcodes
  const processIframeShortcodes = (content) => {
    // Find all [iframe] shortcodes in content
    const iframeRegex = /\[iframe\](.*?),(.*?),(.*?)\[\/iframe\]/g;
    let match;
    let processedContent = content;
    
    while ((match = iframeRegex.exec(content)) !== null) {
      const fullMatch = match[0];
      const src = match[1];
      const width = match[2];
      const height = match[3];
      
      // Replace shortcode with iframe HTML
      processedContent = processedContent.replace(
        fullMatch,
        `<iframe src="${src}" width="${width}" height="${height}" allowfullscreen loading="lazy"></iframe>`
      );
    }
    
    return processedContent;
  };
  
  // Handle the actual rendering, finding placeholders and replacing with React components
  React.useEffect(() => {
    if (!content) return;
    
    // Find all .swf-embed placeholders and replace with RuffleWrapper
    const swfEmbeds = document.querySelectorAll('.swf-embed');
    swfEmbeds.forEach(embed => {
      const src = embed.getAttribute('data-src');
      const width = parseInt(embed.getAttribute('data-width'));
      const height = parseInt(embed.getAttribute('data-height'));
      
      // Create the RuffleWrapper instance and render it into the placeholder
      if (src) {
        const container = document.createElement('div');
        embed.appendChild(container);
        
        // Use ReactDOM to render the RuffleWrapper into the container
        ReactDOM.render(
          <RuffleWrapper src={src} width={width} height={height} />,
          container
        );
      }
    });
    
    // Cleanup on unmount
    return () => {
      swfEmbeds.forEach(embed => {
        // Clean up any React components we rendered
        const reactContainer = embed.querySelector('div');
        if (reactContainer) {
          ReactDOM.unmountComponentAtNode(reactContainer);
        }
      });
    };
  }, [content]);
  
  return (
    <ContentWrapper dangerouslySetInnerHTML={{ __html: processContent() }} />
  );
}

export default PostContent;
