import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslations } from '../translations';

const FooterContainer = styled.footer`
    background-color: var(--gray-light);
    padding: 2rem 1rem;
    margin-top: 3rem;
    font-size: 0.9rem;
`;

const FooterContent = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    
    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
    }
`;

const Copyright = styled.div`
    color: var(--text-primary);
    margin-bottom: 1rem;
    
    @media (min-width: 768px) {
        margin-bottom: 0;
    }
`;

const FooterLinks = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    
    a {
        color: var(--primary-blue);
        text-decoration: none;
        
        &:hover {
            text-decoration: underline;
        }
    }
`;

function Footer() {
    const currentYear = new Date().getFullYear();
    const t = useTranslations();
    
    return (
        <FooterContainer>
            <FooterContent>
                <Copyright>
                    © 2008-{currentYear} ilMare Games. {t.allRightsReserved}.
                </Copyright>
                
                <FooterLinks>
                    <a href="https://googamez.com" target="_blank" rel="noopener">GooGameZ</a>
                    <Link to="/about">{t.about}</Link>
                    <Link to="/contact">{t.contact}</Link>
                    <Link to="/privacy">{t.cookies.privacyPolicy}</Link>
                </FooterLinks>
            </FooterContent>
        </FooterContainer>
    );
}

export default Footer;
