import Ruffle from '@ruffle-rs/ruffle';

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const GameContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #2768CB;
    border: 0.3rem solid #FDFEFE;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    max-width: 90vw;
    margin: 2rem auto;
`;

const FullscreenButton = styled.button`
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    padding: 0.5rem 1rem;
    background-color: #F62F2F;
    color: #fff;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;

    &:hover {
        background-color: #d14a4a;
    }
`;

function GameEmbed({ url, width, height }) {
    const containerRef = useRef(null);
    const playerRef = useRef(null);
    const finalUrl = url && url.length > 0 ? url[0] : "";

    const [scaledDimensions, setScaledDimensions] = useState({ width, height });

    const toggleFullscreen = () => {
        if (containerRef.current.requestFullscreen) {
            containerRef.current.requestFullscreen();
        }
    };    

    // Función para ajustar el tamaño del contenedor basado en la ventana
    const scaleGame = () => {
        const aspectRatio = width / height;
        const screenWidth = window.innerWidth * 0.9;  // 90% del ancho de la ventana
        const screenHeight = window.innerHeight * 0.8; // 80% del alto de la ventana

        if (screenWidth / aspectRatio <= screenHeight) {
            // Ajustar por ancho
            setScaledDimensions({
                width: screenWidth,
                height: screenWidth / aspectRatio
            });
        } else {
            // Ajustar por alto
            setScaledDimensions({
                width: screenHeight * aspectRatio,
                height: screenHeight
            });
        }
    };

    useEffect(() => {
        // Escalar el juego cuando se monta el componente y en cada cambio de tamaño de ventana
        scaleGame();
        window.addEventListener("resize", scaleGame);

        return () => window.removeEventListener("resize", scaleGame);
    }, [width, height]);

    useEffect(() => {
        if (finalUrl.endsWith('.swf')) {
            const loadPlayer = async () => {
                const rufflePlayer = window.RufflePlayer.newest();
                const player = rufflePlayer.createPlayer();
                playerRef.current = player;
                containerRef.current.appendChild(player);

                // Carga el archivo SWF ajustando la URL según sea necesario
                // TODO: REMOVE
                //player.load({ url: finalUrl.replace("googamez.com", "googamez.local") });
                player.load({ url: finalUrl });
                player.style.width = "100vw"; 
                player.style.height = "100vh";
            };

            loadPlayer();

            // Función de limpieza para desmontar el player cuando el componente o la URL cambian
            return () => {
                if (playerRef.current) {
                    playerRef.current.remove();
                    playerRef.current = null;
                }
                if (containerRef.current) {
                    containerRef.current.innerHTML = '';
                }
            };
        }
    }, [finalUrl]);

    return (
        <GameContainer ref={containerRef} style={{ width: scaledDimensions.width, height: scaledDimensions.height }}>
            {finalUrl.endsWith('.swf') ? (
                <div ref={containerRef} />
            ) : (
                <iframe
                    src={finalUrl}
                    width={scaledDimensions.width}
                    height={scaledDimensions.height}
                    title="Game"
                    style={{ border: "none" }}
                />
            )}
            
        </GameContainer>
    );
}

export default GameEmbed;
