import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import GameCard from './GameCard';
import { useTranslations } from '../translations';

const GameListContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
`;

const GameListHeader = styled.div`
    margin-bottom: 2rem;
    text-align: center;
`;

const GameListTitle = styled.h1`
    font-size: 2rem;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
`;

const GameListDescription = styled.p`
    color: #666;
    max-width: 700px;
    margin: 0 auto;
`;

const GameGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    
    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

const LoadMoreButton = styled.button`
    background-color: var(--primary-blue);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    margin: 2rem auto 0;
    display: block;
    transition: background-color 0.3s;
    
    &:hover {
        background-color: var(--secondary-blue);
    }
    
    &:disabled {
        background-color: var(--gray-light);
        color: #666;
        cursor: not-allowed;
    }
`;

const LoadingMessage = styled.div`
    text-align: center;
    padding: 2rem;
    color: #666;
    font-style: italic;
`;

function GameList({ allCategories }) {
    const { categorySlug } = useParams();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [categoryInfo, setCategoryInfo] = useState(null);
    const t = useTranslations();
    
    useEffect(() => {
        setLoading(true);
        setPosts([]);
        setPage(1);
        setHasMore(true);
        
        // Find category info if categorySlug is present
        if (categorySlug && allCategories && allCategories.length) {
            const category = allCategories.find(cat => cat.slug === categorySlug);
            setCategoryInfo(category || null);
        } else {
            setCategoryInfo(null);
        }
        
        fetchPosts(1, true);
    }, [categorySlug, allCategories]);
    
    const fetchPosts = async (pageNum, reset = false) => {
        try {
            let url = `/wp-json/wp/v2/posts?_embed&per_page=9&page=${pageNum}`;
            
            if (categorySlug) {
                // Find category ID from slug
                const category = allCategories.find(cat => cat.slug === categorySlug);
                if (category) {
                    url += `&categories=${category.id}`;
                }
            }
            
            const response = await fetch(url);
            const totalPages = parseInt(response.headers.get('X-WP-TotalPages') || '1');
            const data = await response.json();
            
            setPosts(prev => reset ? data : [...prev, ...data]);
            setHasMore(pageNum < totalPages);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching posts:', error);
            setLoading(false);
        }
    };
    
    const loadMore = () => {
        const nextPage = page + 1;
        setPage(nextPage);
        fetchPosts(nextPage);
    };
    
    return (
        <GameListContainer>
            <GameListHeader>
                <GameListTitle>
                    {categoryInfo ? categoryInfo.name : t.gameList.allArticles}
                </GameListTitle>
                
                {categoryInfo && categoryInfo.description && (
                    <GameListDescription 
                        dangerouslySetInnerHTML={{ __html: categoryInfo.description }} 
                    />
                )}
            </GameListHeader>
            
            {loading && posts.length === 0 ? (
                <LoadingMessage>{t.gameList.loadingArticles}</LoadingMessage>
            ) : (
                <>
                    <GameGrid>
                        {posts.map(post => (
                            <GameCard 
                                key={post.id} 
                                post={post} 
                                categorySlug={categorySlug} 
                            />
                        ))}
                    </GameGrid>
                    
                    {hasMore && (
                        <LoadMoreButton onClick={loadMore} disabled={loading}>
                            {loading ? t.loading : t.gameList.loadMore}
                        </LoadMoreButton>
                    )}
                </>
            )}
        </GameListContainer>
    );
}

export default GameList;