import React, { createContext, useState, useEffect, useContext } from 'react';

// Create context
export const LanguageContext = createContext();

// Language options
export const languages = {
  EN: 'en',
  ES: 'es'
};

// Provider component
export const LanguageProvider = ({ children }) => {
  // Try to get language from localStorage or browser settings, default to English
  const getBrowserLanguage = () => {
    const browserLang = navigator.language || navigator.userLanguage;
    return browserLang && browserLang.startsWith('es') ? languages.ES : languages.EN;
  };

  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('preferredLanguage');
    return savedLanguage || getBrowserLanguage();
  });

  // Save language preference to localStorage when it changes
  useEffect(() => {
    localStorage.setItem('preferredLanguage', language);
  }, [language]);

  // Switch language function
  const switchLanguage = (lang) => {
    if (Object.values(languages).includes(lang)) {
      setLanguage(lang);
    }
  };

  return (
    <LanguageContext.Provider value={{ language, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook for using the language context
export const useLanguage = () => useContext(LanguageContext);
