import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const FlashContainer = styled.div`
  width: 100%;
  max-width: ${props => props.width}px;
  height: ${props => props.height}px;
  margin: 1rem auto;
  background-color: var(--gray-light);
  position: relative;
  border-radius: 4px;
  overflow: hidden;
`;

const LoadingMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
  background-color: var(--gray-light);
  font-size: 1rem;
  z-index: 1;
`;

const ErrorMessage = styled(LoadingMessage)`
  background-color: rgba(255, 235, 235, 0.9);
  color: #d32f2f;
`;

function RuffleWrapper({ src, width = 800, height = 600 }) {
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ruffleInstance, setRuffleInstance] = useState(null);

  useEffect(() => {
    // Load Ruffle from CDN if it's not already loaded
    if (!window.RufflePlayer) {
      const script = document.createElement('script');
      script.src = 'https://unpkg.com/@ruffle-rs/ruffle';
      script.async = true;
      
      script.onload = () => {
        // Initialize Ruffle after script loads
        initRuffle();
      };
      
      script.onerror = () => {
        setError('Error cargando el emulador de Flash (Ruffle)');
        setLoading(false);
      };
      
      document.body.appendChild(script);
    } else {
      // Ruffle already loaded, initialize
      initRuffle();
    }

    // Cleanup function
    return () => {
      if (ruffleInstance) {
        try {
          ruffleInstance.destroy();
        } catch (e) {
          console.error('Error cleaning up Ruffle instance:', e);
        }
      }
    };
  }, [src]);

  const initRuffle = async () => {
    if (!containerRef.current) return;

    try {
      // Wait for Ruffle to be fully initialized
      if (window.RufflePlayer && window.RufflePlayer.newest) {
        // Create a Ruffle player instance
        const ruffle = window.RufflePlayer.newest();
        const player = ruffle.createPlayer();
        containerRef.current.appendChild(player);
        
        // Configure player
        player.style.width = '100%';
        player.style.height = '100%';
        
        // Load the SWF file
        await player.load({ url: src });
        
        setRuffleInstance(player);
        setLoading(false);
      } else {
        setTimeout(initRuffle, 100); // Poll until Ruffle is available
      }
    } catch (err) {
      console.error('Error initializing Ruffle:', err);
      setError(`No se pudo cargar el juego Flash: ${err.message || 'Error desconocido'}`);
      setLoading(false);
    }
  };

  return (
    <FlashContainer width={width} height={height} ref={containerRef}>
      {loading && (
        <LoadingMessage>
          <div>Cargando juego Flash...</div>
          <div className="spinner" style={{ marginTop: '10px' }}>⟳</div>
        </LoadingMessage>
      )}
      
      {error && (
        <ErrorMessage>
          <div>{error}</div>
          <div style={{ fontSize: '0.8rem', marginTop: '10px' }}>Los navegadores modernos ya no soportan Flash directamente.</div>
        </ErrorMessage>
      )}
    </FlashContainer>
  );
}

export default RuffleWrapper;
