import React from 'react';
import styled from 'styled-components';
import { useTranslations } from '../translations';

const Wrapper = styled.div`
    max-width: 800px;
    margin: 2rem auto;
    padding: 1rem;
    color: #333;
    line-height: 1.6;
`;

const ProfileSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    text-align: center;
`;

const DeveloperName = styled.h1`
    font-size: 2.5rem;
    color: #2768CB;
    margin-bottom: 1rem;
`;

const ProfileImage = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const DeveloperTitle = styled.h2`
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 1rem;
`;

const SocialLinks = styled.div`
    margin: 1rem 0;
    
    a {
        display: inline-flex;
        align-items: center;
        color: #2768CB;
        text-decoration: none;
        margin: 0 0.5rem;
        
        &:hover {
            text-decoration: underline;
        }
        
        svg {
            margin-right: 0.5rem;
        }
    }
`;

const BioSection = styled.div`
    margin-bottom: 2rem;
`;

const SectionTitle = styled.h3`
    font-size: 1.5rem;
    color: #2768CB;
    margin-bottom: 1rem;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 0.5rem;
`;

const GamesSection = styled.div`
    margin-bottom: 2rem;
`;

const GamesList = styled.ul`
    list-style-type: none;
    padding: 0;
    
    li {
        margin-bottom: 1rem;
        padding-left: 1.5rem;
        position: relative;
        
        &:before {
            content: "•";
            color: #2768CB;
            position: absolute;
            left: 0;
            font-size: 1.2rem;
        }
    }
`;

function About() {
    const t = useTranslations();
    
    return (
        <Wrapper>
            <ProfileSection>
                <DeveloperName>{t.credits.name}</DeveloperName>
                <ProfileImage src="/wp-content/themes/ilmare24k/react-app/build/profile2025.jpg" alt="Juan Ignacio Albanesi" />
                <DeveloperTitle>{t.credits.title}</DeveloperTitle>
                
                <SocialLinks>
                    <a href="https://www.linkedin.com/in/jalbanesi/" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                        </svg>
                        LinkedIn
                    </a>
                </SocialLinks>
            </ProfileSection>
            
            <BioSection>
                <SectionTitle>{t.credits.bioTitle}</SectionTitle>
                <p>{t.credits.bio1}</p>
                <p>{t.credits.bio2}</p>
                <p>{t.credits.bio3}</p>
            </BioSection>
            
            <GamesSection>
                <SectionTitle>{t.credits.gamesTitle}</SectionTitle>
                <GamesList>
                    {t.credits.games && Array.isArray(t.credits.games) ? 
                        t.credits.games.map((game, index) => (
                            <li key={index}><strong>{game.title}</strong> - {game.description}</li>
                        ))
                        : <li>Loading games...</li>
                    }
                </GamesList>
            </GamesSection>
            
            <div>
                <SectionTitle>{t.credits.contactTitle}</SectionTitle>
                <p>{t.credits.contactText}</p>
            </div>
        </Wrapper>
    );
}

export default About;
