import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import AdBanner from './AdBanner';
import GameEmbed from './GameEmbed';
import SocialShareButtons from './SocialShareButtons';
import { useTranslations } from '../translations';

const Wrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    color: #333;
`;

const GameTitle = styled.h1`
    font-size: 2rem;
    font-weight: bold;
    color: #2768CB;
    margin-bottom: 1rem;
    text-align: center;
`;

const GameDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 2rem;
    position: relative;
`;

const GameThumbnail = styled.img`
    width: 150px;
    height: 150px;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
`;

const PlayButton = styled.button`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #2768CB;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #1b4f8a;
    }
`;

const Stats = styled.div`
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    font-size: 1rem;
    color: #555;
    margin-top: 1rem;
`;

const LikeButton = styled.button`
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #fff;
    background-color: #F62F2F;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #d14a4a;
    }
`;

const DescriptionSection = styled.div`
    display: flex;
    gap: 1.5rem;
    margin-top: 1.5rem;
    justify-content: center;
    text-align: left;
    flex-wrap: wrap;
`;

const Description = styled.div`
    width: 45%;
    padding: 1.5rem;
    padding-bottom: 4rem; /* Espacio para el botón */
    background-color: #f7f9fc;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
    position: relative;
    max-height: ${({ showFull }) => (showFull ? 'none' : '200px')};
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        bottom: 0rem; /* Ajustado para no cubrir el botón */
        left: 0;
        width: 100%;
        height: 5rem;
        background: linear-gradient(to top, #f7f9fc, rgba(247, 249, 252, 1), rgba(247, 249, 252, 0));
        display: ${({ showFull }) => (showFull ? 'none' : 'block')};
    }
`;

const Instructions = styled(Description)``;

const ShowMoreButton = styled.button`
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    border: 1px solid #2768CB;
    color: #2768CB;
    cursor: pointer;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    z-index: 1;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #2768CB;
        color: #ffffff;
    }
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
`;

const OverlayContent = styled.div`
    position: relative;
    max-width: 90vw;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 2em;
    right: 0em;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 50%;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
        background-color: rgba(255, 255, 255, 0.3);
        transform: scale(1.1);
    }
`;

const FullscreenButton = styled.button`
    position: absolute;
    bottom: 2em;
    right: 0em;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 50%;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
        background-color: rgba(255, 255, 255, 0.3);
        transform: scale(1.1);
    }
`;

const RelatedGames = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 1.5rem;
    margin-top: 2rem;
`;

const RelatedGame = styled.div`
    text-align: center;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-5px);
    }

    img {
        width: 100px;
        height: 100px;
        border-radius: 0.5rem 0.5rem 0 0;
    }

    h2 {
        font-size: 0.85rem;
        color: #333;
        margin-top: 0.5rem;
        padding: 0.5rem;
    }
`;

function GameDetail({ allCategories }) {
    const { categorySlug, gameName } = useParams();
    const [game, setGame] = useState(null);
    const [relatedGames, setRelatedGames] = useState([]);
    const [gameLoaded, setGameLoaded] = useState(false);
    const [likes, setLikes] = useState(0);
    const [showDescription, setShowDescription] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);
    const containerRef = useRef(null);
    const t = useTranslations();

    // Nuevos estados y referencias
    const [isDescriptionOverflowing, setIsDescriptionOverflowing] = useState(false);
    const [isInstructionsOverflowing, setIsInstructionsOverflowing] = useState(false);
    const descriptionRef = useRef(null);
    const instructionsRef = useRef(null);

    // Estado para almacenar el ID de la categoría
    const [categoryId, setCategoryId] = useState(null);

    // Mapear categorySlug a categoryId
    useEffect(() => {
        if (allCategories && allCategories.length > 0) {
            const category = allCategories.find(cat => cat.slug === categorySlug);
            if (category) {
                setCategoryId(category.id);
            } else {
                console.error('Category not found for slug:', categorySlug);
            }
        }
    }, [allCategories, categorySlug]);

    // Obtener los detalles del juego
    useEffect(() => {
        if (categoryId) {
            fetch(`/wp-json/wp/v2/posts?slug=${gameName}&categories=${categoryId}`)
                .then(response => response.json())
                .then(data => {
                    if (data && data.length > 0) {
                        setGame(data[0]);
                        setLikes(parseInt(data[0].meta.ratings_users[0] || 0));
                    } else {
                        console.error('Game not found');
                    }
                })
                .catch(error => console.error('Error fetching game details:', error));
        }
    }, [gameName, categoryId]);

    // Verificar si el contenido desborda
    useEffect(() => {
        if (game) {
            // Verificar desbordamiento en Descripción
            if (descriptionRef.current) {
                const { scrollHeight, clientHeight } = descriptionRef.current;
                setIsDescriptionOverflowing(scrollHeight > clientHeight);
            }
            // Verificar desbordamiento en Instrucciones
            if (instructionsRef.current) {
                const { scrollHeight, clientHeight } = instructionsRef.current;
                setIsInstructionsOverflowing(scrollHeight > clientHeight);
            }
        }
    }, [game, showDescription, showInstructions, descriptionRef, instructionsRef]);

    // Obtener juegos relacionados
    useEffect(() => {
        if (game) {
            fetch(`/wp-json/wp/v2/posts?categories=${game.categories[0]}&per_page=8&exclude=${game.id}`)
                .then(response => response.json())
                .then(data => setRelatedGames(data))
                .catch(error => console.error('Error fetching related games:', error));
        }
    }, [game]);

    const handlePlayClick = () => setGameLoaded(true);

    const handleLike = () => setLikes(prevLikes => prevLikes + 1);

    const closeOverlay = () => setGameLoaded(false);

    const handleFullscreen = () => {
        if (containerRef.current && containerRef.current.requestFullscreen) {
            containerRef.current.requestFullscreen();
        }
    };

    return (
        <Wrapper>
            {game ? (
                <>
                    <GameTitle>{game.title.rendered}</GameTitle>
                    <GameDetails>
                        <GameThumbnail src={game.meta.thumbnail_url ? game.meta.thumbnail_url[0] : ''} alt={game.title.rendered} />
                        <PlayButton onClick={handlePlayClick}>{t.gameDetail.play}</PlayButton>
                        <SocialShareButtons title={game.title.rendered} url={window.location.href} />
                        <Stats>
                            <div>Views: {game.meta.views ? game.meta.views[0] : 'N/A'}</div>
                        </Stats>
                    </GameDetails>

                    <DescriptionSection>
                        <Description showFull={showDescription} ref={descriptionRef}>
                            <h3>{t.gameDetail.description}</h3>
                            {game.meta.description && (
                                <>
                                    <div dangerouslySetInnerHTML={{ __html: game.meta.description[0] }} />
                                    {isDescriptionOverflowing && (
                                        <ShowMoreButton onClick={() => setShowDescription(!showDescription)}>
                                            {showDescription ? t.gameDetail.showLess : t.gameDetail.showMore}
                                        </ShowMoreButton>
                                    )}
                                </>
                            )}
                        </Description>
                        <Instructions showFull={showInstructions} ref={instructionsRef}>
                            <h3>{t.gameDetail.instructions}</h3>
                            {game.meta.instructions && (
                                <>
                                    <div dangerouslySetInnerHTML={{ __html: game.meta.instructions[0] }} />
                                    {isInstructionsOverflowing && (
                                        <ShowMoreButton onClick={() => setShowInstructions(!showInstructions)}>
                                            {showInstructions ? t.gameDetail.showLess : t.gameDetail.showMore}
                                        </ShowMoreButton>
                                    )}
                                </>
                            )}
                        </Instructions>
                    </DescriptionSection>

                    {gameLoaded && (
                        <Overlay>
                            <OverlayContent ref={containerRef}>
                                <GameEmbed url={game.meta.swf_url} width={game.meta.width || 600} height={game.meta.height || 400} />
                                <CloseButton onClick={closeOverlay}>&times;</CloseButton>
                                <FullscreenButton onClick={handleFullscreen}>⛶</FullscreenButton>
                            </OverlayContent>
                        </Overlay>
                    )}

                    {gameLoaded && (
                        <Overlay>
                            <OverlayContent ref={containerRef}>
                                <GameEmbed url={game.meta.swf_url} width={game.meta.width || 600} height={game.meta.height || 400} />
                                <CloseButton onClick={closeOverlay}>&times;</CloseButton>
                                <FullscreenButton onClick={handleFullscreen}>⛶</FullscreenButton>
                            </OverlayContent>
                        </Overlay>
                    )}

                    <h2 style={{ textAlign: "center", color: "#2768CB", marginTop: "2rem" }}>{t.gameDetail.relatedGames}</h2>
                    {relatedGames && (
                        <RelatedGames>
                            {relatedGames.map(relatedGame => (
                                <RelatedGame key={relatedGame.id}>
                                    <Link to={`/${relatedGame.link.split('/')[3]}/${relatedGame.slug}`}>
                                        <img src={relatedGame.meta.thumbnail_url ? relatedGame.meta.thumbnail_url : ''} alt={relatedGame.title.rendered} />
                                        <h2>{relatedGame.title.rendered}</h2>
                                    </Link>
                                </RelatedGame>
                            ))}
                        </RelatedGames>
                    )}
                </>
            ) : (
                // Renderiza el mismo DOM pero muestra estados de carga
                <>
                    <GameTitle>{t.gameDetail.loading}</GameTitle>
                    <GameDetails>
                        <div>{t.gameDetail.loadingGameDetails}</div>
                    </GameDetails>
                </>
            )}
        </Wrapper>
    );
}

export default GameDetail;

