import React from 'react';
import styled from 'styled-components';
import { useLanguage, languages } from '../context/LanguageContext';

const SwitcherContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const LanguageButton = styled.button`
  background: ${props => props.active ? 'white' : 'transparent'};
  color: ${props => props.active ? 'var(--primary-blue)' : 'white'};
  border: 1px solid white;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background: ${props => props.active ? 'white' : 'rgba(255, 255, 255, 0.2)'};
  }
`;

function LanguageSwitcher() {
  const { language, switchLanguage } = useLanguage();
  
  return (
    <SwitcherContainer>
      <LanguageButton 
        active={language === languages.EN}
        onClick={() => switchLanguage(languages.EN)}
      >
        EN
      </LanguageButton>
      <LanguageButton 
        active={language === languages.ES}
        onClick={() => switchLanguage(languages.ES)}
      >
        ES
      </LanguageButton>
    </SwitcherContainer>
  );
}

export default LanguageSwitcher;
