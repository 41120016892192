import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslations } from '../translations';
import LanguageSwitcher from './LanguageSwitcher';

const HeaderContainer = styled.header`
    background-color: var(--primary-blue);
    color: white;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative; /* Add this to create a positioning context */
`;

const HeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    max-width: 1200px;
    margin: 0 auto;
    
    @media (min-width: 600px) {
        padding: 1rem 2rem;
    }
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    
    a {
        display: flex;
        align-items: center;
        gap: 10px;
        color: white;
        text-decoration: none;
    }
    
    @media (min-width: 600px) {
        font-size: 1.8rem;
    }
`;

const LogoImage = styled.img`
    width: 40px;
    height: 40px;
    
    @media (min-width: 600px) {
        width: 50px;
        height: 50px;
    }
`;

const LogoText = styled.span`
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Quicksand', 'Segoe UI', Tahoma, sans-serif;
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    background: linear-gradient(45deg, #ffffff, #e6e6e6);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    padding: 2px 5px;
    border-radius: 8px;
    
    @media (min-width: 600px) {
        font-size: 1.8rem;
    }
`;

const MobileMenuButton = styled.button`
    background: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    display: block;
    padding: 0.5rem;
    
    @media (min-width: 1024px) {
        display: none;
    }
`;

const NavMenu = styled.nav`
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--primary-blue);
    padding: 1rem;
    z-index: 100; /* Increased z-index */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
    
    @media (min-width: 1024px) {
        display: flex;
        position: static;
        flex-direction: row;
        padding: 0;
        gap: 1.5rem;
        box-shadow: none; /* Remove shadow on desktop */
    }
`;

const NavLink = styled(Link)`
    color: white;
    text-decoration: none;
    padding: 0.5rem 0;
    font-weight: ${props => props.active ? '700' : '400'};
    position: relative;
    
    &:hover {
        text-decoration: underline;
    }
    
    @media (min-width: 1024px) {
        padding: 0.5rem 0;
        
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: white;
            bottom: -5px;
            left: 0;
            transform: scaleX(${props => props.active ? '1' : '0'});
            transition: transform 0.3s ease;
        }
        
        &:hover:after {
            transform: scaleX(1);
        }
    }
`;

const NavContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    
    @media (min-width: 1024px) {
        flex-direction: row;
    }
`;

function MainMenu({ allCategories }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const [mainCategories, setMainCategories] = useState([]);
    const t = useTranslations();
    
    useEffect(() => {
        // Filter for main categories: Games, Prototypes, News, IA
        const mainCats = ['games', 'prototypes', 'news', 'ia']; 
        
        if (allCategories && allCategories.length > 0) {
            const filteredCategories = allCategories.filter(cat => 
                mainCats.includes(cat.slug.toLowerCase())
            );
            setMainCategories(filteredCategories);
        }
    }, [allCategories]);
    
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const closeMenu = () => setIsMenuOpen(false);
    
    // Check if a path is active
    const isActive = (path) => {
        if (path === '/' && location.pathname === '/') return true;
        if (path !== '/' && location.pathname.startsWith(path)) return true;
        return false;
    };
    
    // Get translated category name
    const getTranslatedCategoryName = (category) => {
        const slug = category.slug.toLowerCase();
        return t.mainMenu[slug] || category.name;
    };
    
    return (
        <HeaderContainer>
            <HeaderContent>
                <Logo>
                    <Link to="/">
                        <LogoImage src="/wp-content/themes/ilmare24k/react-app/build/ilmare.png" alt="ilMare Games Logo" />
                        <LogoText>ilMare Games</LogoText>
                    </Link>
                </Logo>
                
                <NavContainer>
                    <LanguageSwitcher />
                    
                    <MobileMenuButton onClick={toggleMenu} aria-label="Toggle menu">
                        {isMenuOpen ? '✕' : '☰'}
                    </MobileMenuButton>
                </NavContainer>
                
                <NavMenu isOpen={isMenuOpen}>
                    <NavLink to="/" active={isActive('/')} onClick={closeMenu}>
                        {t.home}
                    </NavLink>
                    
                    {mainCategories.map(category => (
                        <NavLink 
                            key={category.id}
                            to={`/category/${category.slug}`}
                            active={isActive(`/category/${category.slug}`)}
                            onClick={closeMenu}
                        >
                            {getTranslatedCategoryName(category)}
                        </NavLink>
                    ))}
                    
                    <NavLink to="/about" active={isActive('/about')} onClick={closeMenu}>
                        {t.about}
                    </NavLink>
                    
                    <NavLink to="/contact" active={isActive('/contact')} onClick={closeMenu}>
                        {t.contact}
                    </NavLink>
                </NavMenu>
            </HeaderContent>
        </HeaderContainer>
    );
}

export default MainMenu;
