import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslations } from '../translations';
import { useLanguage } from '../context/LanguageContext';

const CommentsContainer = styled.div`
  margin-top: 2rem;
`;

const CommentItem = styled.div`
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--gray-light);
  
  &:last-child {
    border-bottom: none;
  }
`;

const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const CommentAuthor = styled.span`
  font-weight: 500;
  color: var(--text-primary);
`;

const CommentDate = styled.span`
  font-size: 0.85rem;
  color: #666;
`;

const CommentContent = styled.div`
  margin-top: 0.5rem;
  line-height: 1.6;
  
  p:last-child {
    margin-bottom: 0;
  }
`;

const CommentForm = styled.form`
  margin-top: 2rem;
  border-top: 1px solid var(--gray-light);
  padding-top: 1.5rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  min-height: 120px;
`;

const SubmitButton = styled.button`
  background-color: var(--primary-blue);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: var(--secondary-blue);
  }
  
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #d32f2f;
  margin-top: 1rem;
  padding: 0.75rem;
  background-color: #ffebee;
  border-radius: 4px;
`;

const SuccessMessage = styled.div`
  color: #388e3c;
  margin-top: 1rem;
  padding: 0.75rem;
  background-color: #e8f5e9;
  border-radius: 4px;
`;

const NoComments = styled.p`
  font-style: italic;
  color: #666;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const CommentStatus = styled.div`
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 4px;
  background-color: #e3f2fd;
  color: #0d47a1;
`;

function CommentList({ postId }) {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    author: localStorage.getItem('comment_author_name') || '',
    email: localStorage.getItem('comment_author_email') || '',
    content: '',
    rememberMe: localStorage.getItem('comment_author_name') ? true : false,
  });
  const [submitStatus, setSubmitStatus] = useState({
    submitting: false,
    success: false,
    error: null
  });
  const [commentsAllowed, setCommentsAllowed] = useState(true);
  
  const t = useTranslations();
  const { language } = useLanguage();
  
  // Check if comments are allowed for this post
  useEffect(() => {
    if (!postId) return;
    
    // Fetch post settings to check if comments are open
    fetch(`/wp-json/wp/v2/posts/${postId}`)
      .then(response => response.json())
      .then(data => {
        setCommentsAllowed(data.comment_status === 'open');
      })
      .catch(err => {
        console.error('Error checking comment status:', err);
      });
  }, [postId]);
  
  // Fetch comments when component mounts or postId changes
  useEffect(() => {
    if (!postId) return;
    
    setLoading(true);
    fetch(`/wp-json/wp/v2/comments?post=${postId}&per_page=100&order=asc`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch comments');
        }
        return response.json();
      })
      .then(data => {
        setComments(data);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching comments:', err);
        setError(err.message);
        setLoading(false);
      });
  }, [postId]);
  
  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  
  // Sanitize comment content
  const sanitizeComment = (content) => {
    if (!content) return '';
    
    // Replace < and > with HTML entities to prevent HTML tags
    let sanitized = content
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
    
    // Remove any script tags that might have slipped through
    sanitized = sanitized.replace(/<script.*?>.*?<\/script>/gi, '');
    
    // Replace multiple newlines with max two line breaks
    sanitized = sanitized.replace(/(\r\n|\n|\r){3,}/g, '\n\n');
    
    // Replace URLs with clickable links
    sanitized = sanitized.replace(
      /(https?:\/\/[^\s]+)/g, 
      (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
    );
    
    return sanitized;
  };
  
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!formData.content.trim()) {
      setSubmitStatus({
        submitting: false,
        success: false,
        error: t.post.commentContentRequired
      });
      return;
    }
    
    // Save author info if rememberMe is checked
    if (formData.rememberMe) {
      localStorage.setItem('comment_author_name', formData.author);
      localStorage.setItem('comment_author_email', formData.email);
    } else {
      localStorage.removeItem('comment_author_name');
      localStorage.removeItem('comment_author_email');
    }
    
    setSubmitStatus({
      submitting: true,
      success: false,
      error: null
    });
    
    // Sanitize the comment content
    const sanitizedContent = sanitizeComment(formData.content);
    
    // Prepare comment data
    const commentData = {
      post: postId,
      author_name: formData.author || 'Anonymous',
      author_email: formData.email || 'anonymous@example.com',
      content: sanitizedContent
    };
    
    // Send to WordPress API
    fetch('/wp-json/wp/v2/comments', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(commentData)
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 429) {
            throw new Error(t.post.commentRateLimited);
          }
          return response.json().then(err => {
            if (err.message) {
              throw new Error(err.message);
            }
            throw new Error(t.post.commentError);
          });
        }
        return response.json();
      })
      .then(data => {
        // Update comments list with new comment
        setComments(prev => [...prev, data]);
        // Reset form content field but keep author info
        setFormData(prev => ({
          ...prev,
          content: ''
        }));
        // Set success status
        setSubmitStatus({
          submitting: false,
          success: true,
          error: null
        });
        
        // Clear success message after 5 seconds
        setTimeout(() => {
          setSubmitStatus(prev => ({
            ...prev,
            success: false
          }));
        }, 5000);
      })
      .catch(err => {
        console.error('Error posting comment:', err);
        setSubmitStatus({
          submitting: false,
          success: false,
          error: err.message
        });
      });
  };
  
  // Format date based on current language
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString(
      language === 'es' ? 'es-ES' : 'en-US', 
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }
    );
  };
  
  if (loading) {
    return <p>{t.loading}...</p>;
  }
  
  if (error) {
    return <p>{t.error}: {error}</p>;
  }
  
  return (
    <CommentsContainer>
      <h3>{t.post.comments} ({comments.length})</h3>
      
      {comments.length === 0 ? (
        <NoComments>{t.post.noComments}</NoComments>
      ) : (
        comments.map(comment => (
          <CommentItem key={comment.id}>
            <CommentHeader>
              <CommentAuthor>{comment.author_name || 'Anonymous'}</CommentAuthor>
              <CommentDate>{formatDate(comment.date)}</CommentDate>
            </CommentHeader>
            <CommentContent dangerouslySetInnerHTML={{ __html: comment.content.rendered }} />
          </CommentItem>
        ))
      )}
      
      {!commentsAllowed ? (
        <CommentStatus>{t.post.commentsClosed}</CommentStatus>
      ) : (
        <CommentForm onSubmit={handleSubmit}>
          <h4>{t.post.leaveComment}</h4>
          
          <FormGroup>
            <Label htmlFor="author">{t.post.name} ({t.post.optional})</Label>
            <Input
              type="text"
              id="author"
              name="author"
              value={formData.author}
              onChange={handleChange}
              placeholder={t.post.anonymous}
            />
          </FormGroup>
          
          <FormGroup>
            <Label htmlFor="email">{t.post.email} ({t.post.optional})</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder={t.post.emailPrivate}
            />
          </FormGroup>
          
          <FormGroup>
            <Label htmlFor="content">{t.post.comment} *</Label>
            <Textarea
              id="content"
              name="content"
              value={formData.content}
              onChange={handleChange}
              required
              placeholder={t.post.commentPlaceholder}
            />
          </FormGroup>
          
          <CheckboxContainer>
            <Checkbox
              type="checkbox"
              id="rememberMe"
              name="rememberMe"
              checked={formData.rememberMe}
              onChange={handleChange}
            />
            <Label htmlFor="rememberMe" style={{ display: 'inline', marginBottom: 0 }}>
              {t.post.rememberMe}
            </Label>
          </CheckboxContainer>
          
          <SubmitButton 
            type="submit" 
            disabled={submitStatus.submitting}
          >
            {submitStatus.submitting ? t.post.submitting : t.post.submitComment}
          </SubmitButton>
          
          {submitStatus.error && (
            <ErrorMessage>{submitStatus.error}</ErrorMessage>
          )}
          
          {submitStatus.success && (
            <SuccessMessage>{t.post.commentSuccess}</SuccessMessage>
          )}
        </CommentForm>
      )}
    </CommentsContainer>
  );
}

export default CommentList;
