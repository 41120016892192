import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslations } from '../translations';

const CookieBannerContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--primary-blue);
    color: white;
    text-align: center;
    padding: 1rem;
    font-size: 0.9rem;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
    z-index: 100;
    font-family: 'Open Sans', 'Roboto', 'Helvetica Neue', sans-serif;
`;

const CookieButton = styled.button`
    background-color: white;
    color: var(--primary-blue);
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    cursor: pointer;
    margin-left: 1rem;
    transition: background-color 0.3s;
    font-weight: 600;

    &:hover {
        background-color: var(--gray-light);
    }
`;

function CookieConsent() {
    const [isVisible, setIsVisible] = useState(false);
    const t = useTranslations();

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setIsVisible(false);
    };

    if (!isVisible) return <></>;

    return (
        <CookieBannerContainer>
            {t.cookies.message}
            <a href="/privacy" style={{ color: 'white', marginLeft: '0.25rem', textDecoration: 'underline' }}>
                {t.cookies.privacyPolicy}
            </a>.
            <CookieButton onClick={handleAccept}>{t.cookies.accept}</CookieButton>
        </CookieBannerContainer>
    );
}

export default CookieConsent;
