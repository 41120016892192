import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import PostContent from './PostContent';
import RuffleWrapper from './RuffleWrapper';
import ReactDOM from 'react-dom';
import { useTranslations } from '../translations';
import { useLanguage } from '../context/LanguageContext';
import CommentList from './CommentList';

const PostContainer = styled.article`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
`;

const PostHeader = styled.header`
  margin-bottom: 2rem;
  text-align: center;
`;

const PostTitle = styled.h1`
  font-size: 2.5rem;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  line-height: 1.2;
`;

const PostMeta = styled.div`
  color: #666;
  font-size: 0.9rem;
  margin-top: 1rem;
  
  a {
    color: var(--primary-blue);
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const FeaturedImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

const SocialShareContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
`;

const ShareButton = styled.a`
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--primary-blue);
  color: white;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: var(--secondary-blue);
  }
  
  svg {
    margin-right: 0.5rem;
  }
`;

const CommentSection = styled.section`
  margin-top: 3rem;
  border-top: 1px solid var(--gray-light);
  padding-top: 2rem;
`;

const CommentTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--text-primary);
`;

const NavigationLinks = styled.nav`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--gray-light);
  
  a {
    display: inline-flex;
    align-items: center;
    color: var(--primary-blue);
    text-decoration: none;
    font-weight: 500;
    
    &:hover {
      text-decoration: underline;
    }
    
    &[rel="prev"] {
      &:before {
        content: "←";
        margin-right: 0.5rem;
      }
    }
    
    &[rel="next"] {
      &:after {
        content: "→";
        margin-left: 0.5rem;
      }
    }
  }
`;

const ErrorMessage = styled.div`
  background-color: #ffebee;
  color: #c62828;
  padding: 1rem;
  border-radius: 4px;
  margin: 2rem 0;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  
  .loader {
    border: 4px solid var(--gray-light);
    border-top: 4px solid var(--primary-blue);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

function PostDetail() {
  const { categorySlug, postSlug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [prevPost, setPrevPost] = useState(null);
  const [nextPost, setNextPost] = useState(null);
  const t = useTranslations();
  const { language } = useLanguage();
  
  // Fetch post data
  useEffect(() => {
    setLoading(true);
    setError(null);
    
    // First, get the post by slug
    fetch(`/wp-json/wp/v2/posts?slug=${postSlug}&_embed`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Post not found');
        }
        return response.json();
      })
      .then(data => {
        if (data && data.length > 0) {
          setPost(data[0]);
          // After getting the post, fetch previous and next posts
          fetchAdjacentPosts(data[0].id, data[0].categories[0], data[0].date);
        } else {
          throw new Error('Post not found');
        }
      })
      .catch(err => {
        console.error('Error fetching post:', err);
        setError(err.message);
        setLoading(false);
      });
  }, [postSlug, categorySlug]);
  
  // Fetch previous and next posts
  const fetchAdjacentPosts = (postId, categoryId, postDate) => {
    // Get previous post
    fetch(`/wp-json/wp/v2/posts?categories=${categoryId}&order=desc&per_page=1&before=${new Date(postDate).toISOString()}`)
      .then(res => res.json())
      .then(data => {
        if (data.length > 0) {
          setPrevPost(data[0]);
        }
      })
      .catch(err => console.error('Error fetching previous post:', err));
    
    // Get next post
    fetch(`/wp-json/wp/v2/posts?categories=${categoryId}&order=asc&per_page=1&after=${new Date(postDate).toISOString()}`)
      .then(res => res.json())
      .then(data => {
        if (data.length > 0) {
          setNextPost(data[0]);
        }
      })
      .catch(err => console.error('Error fetching next post:', err))
      .finally(() => setLoading(false));
  };
  
  // Format date nicely based on current language
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString(
      language === 'es' ? 'es-ES' : 'en-US', 
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
    );
  };
  
  // Get category name
  const getCategoryName = () => {
    if (post && post._embedded && post._embedded['wp:term']) {
      const categories = post._embedded['wp:term'][0];
      return categories[0] ? categories[0].name : '';
    }
    return '';
  };
  
  // Get featured image URL
  const getFeaturedImage = () => {
    if (post && post._embedded && post._embedded['wp:featuredmedia']) {
      const media = post._embedded['wp:featuredmedia'][0];
      return media.source_url || '';
    }
    return '';
  };
  
  // Share post functions
  const shareOnTwitter = () => {
    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent(post.title.rendered);
    window.open(`https://twitter.com/intent/tweet?url=${url}&text=${text}`, '_blank');
  };
  
  const shareOnFacebook = () => {
    const url = encodeURIComponent(window.location.href);
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
  };
  
  const shareOnWhatsApp = () => {
    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent(post.title.rendered);
    window.open(`https://wa.me/?text=${text}%20${url}`, '_blank');
  };
  
  if (loading) {
    return (
      <PostContainer>
        <LoadingContainer>
          <div className="loader"></div>
        </LoadingContainer>
      </PostContainer>
    );
  }
  
  if (error) {
    return (
      <PostContainer>
        <ErrorMessage>
          <p>{t.error}: {error}</p>
          <p>{t.post.loadError}</p>
          <Link to="/">{t.backToHome}</Link>
        </ErrorMessage>
      </PostContainer>
    );
  }
  
  if (!post) {
    return (
      <PostContainer>
        <ErrorMessage>
          <p>{t.post.articleNotFound}</p>
          <Link to="/">{t.backToHome}</Link>
        </ErrorMessage>
      </PostContainer>
    );
  }
  
  return (
    <PostContainer>
      <PostHeader>
        <PostTitle dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
        
        <PostMeta>
          <div>
            <time dateTime={post.date}>{formatDate(post.date)}</time>
            {' '}{t.post.in}{' '}
            <Link to={`/category/${categorySlug}`}>{getCategoryName()}</Link>
          </div>
        </PostMeta>
      </PostHeader>
      
      {getFeaturedImage() && (
        <FeaturedImage src={getFeaturedImage()} alt={post.title.rendered} />
      )}
      
      <PostContent content={post.content.rendered} />
      
      <SocialShareContainer>
        <ShareButton href="#" onClick={(e) => { e.preventDefault(); shareOnTwitter(); }}>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
            <path d="M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z" />
          </svg>
          {t.post.share} Twitter
        </ShareButton>
        
        <ShareButton href="#" onClick={(e) => { e.preventDefault(); shareOnFacebook(); }}>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
            <path d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h8.615v-6.96h-2.338v-2.725h2.338v-2c0-2.325 1.42-3.592 3.5-3.592.699-.002 1.399.034 2.095.107v2.42h-1.435c-1.128 0-1.348.538-1.348 1.325v1.735h2.697l-.35 2.725h-2.348V21H20a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1z" />
          </svg>
          {t.post.share} Facebook
        </ShareButton>
        
        <ShareButton href="#" onClick={(e) => { e.preventDefault(); shareOnWhatsApp(); }}>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
            <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z" />
          </svg>
          {t.post.share} WhatsApp
        </ShareButton>
      </SocialShareContainer>
      
      <NavigationLinks>
        {prevPost && (
          <Link to={`/${categorySlug}/${prevPost.slug}`} rel="prev">
            {prevPost.title.rendered}
          </Link>
        )}
        
        {!prevPost && <div></div>}
        
        {nextPost && (
          <Link to={`/${categorySlug}/${nextPost.slug}`} rel="next">
            {nextPost.title.rendered}
          </Link>
        )}
      </NavigationLinks>
      
      <CommentSection>
        <CommentTitle>{t.post.comments}</CommentTitle>
        {/* Replace static message with the CommentList component */}
        {post && <CommentList postId={post.id} />}
      </CommentSection>
    </PostContainer>
  );
}

export default PostDetail;
